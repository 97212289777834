import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import RootLayout from "./components/layouts/root";
import HomePage from "./pages/home";
import ErrorPage from "./pages/error-page";
import { HelmetProvider } from "react-helmet-async";
import loadable from "@loadable/component";
import Intercom from "@intercom/messenger-js-sdk";

const SupportPage = loadable(() => import("./pages/support"));
const PricingPage = loadable(() => import("./pages/pricing"));
const TosPage = loadable(() => import("./pages/tos"));
const PrivacyPolicyPage = loadable(() => import("./pages/privacy-policy"));
const LocationsPage = loadable(() => import("./pages/locations"));
const NotFoundPage = loadable(() => import("./pages/not-found"));
import { Partytown } from "@builder.io/partytown/react";

Intercom({
  app_id: "vpk9gshe",
});

const queryParams = new URLSearchParams(window.location.search);
const campaignName = queryParams.get("c");
if (campaignName) {
  document.cookie = `campaign=${campaignName}; path=/; max-age=3600; domain=thunderproxy.com`;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "",
        index: true,
        element: <HomePage />,
      },
      {
        path: "support",
        index: true,
        element: <SupportPage />,
      },
      {
        path: "pricing",
        index: true,
        element: <PricingPage />,
      },
      {
        path: "tos",
        index: true,
        element: <TosPage />,
      },
      {
        path: "privacy",
        index: true,
        element: <PrivacyPolicyPage />,
      },
      {
        path: "locations",
        index: true,
        element: <LocationsPage />,
      },
      {
        path: "404",
        element: <NotFoundPage />,
      },
      {
        path: "*",
        element: <Navigate to={"/404"} />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
      <Partytown debug={true} forward={["dataLayer.push", "gtag"]} />
    </HelmetProvider>
  </React.StrictMode>,
);
