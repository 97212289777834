import loadable from "@loadable/component";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Header from "../header";

const TrackPageView = loadable(() => import("../track-page-view"));

const Footer = loadable(() => import("../footer"));

export default function RootLayout(): JSX.Element {
  return (
    <div className="bg-background h-full">
      <div className="relative min-h-screen">
        <Header />
        <Outlet />
        <ScrollRestoration />
      </div>
      <Footer />
      <TrackPageView />
    </div>
  );
}
