import { ChevronRightIcon } from "lucide-react";
import { Button } from "../ui/button";
import loadable from "@loadable/component";
import { useEffect, useState } from "react";
import { showMessages } from "@intercom/messenger-js-sdk";

// @ts-expect-error Idk it just doesnt see it in the dir
const ShowingGlobe = loadable(() => import("../ui/globe/globe.jsx"));

export default function HeroSection(): JSX.Element {
  const [countries, setCountries] = useState(null);

  const url = import.meta.env.VITE_DASHBOARD_URL;

  // Fetching the countries JSON data
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("/images/assets/globe.json");
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error("Error fetching the globe data:", error);
      }
    };

    fetchCountries();
  }, []);

  return (
    <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40 lg:h-screen">
      <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
        <div className="flex">
          <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-foreground ring-1 ring-white/20 hover:ring-white/30">
            <span className="font-semibold text-green-600">
              We&apos;re on TrustPilot
            </span>
            <span className="h-4 w-px bg-white" aria-hidden="true" />
            <a
              href="https://trustpilot.com/review/thunderproxy.com"
              className="flex items-center gap-x-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="absolute inset-0" aria-hidden="true" />
              Check our reviews
              <ChevronRightIcon
                className="-mr-2 h-5 w-5 text-muted-foreground"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
        <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-foreground sm:text-6xl">
          Unbeatable quality, incredible prices
        </h1>
        <p className="mt-6 text-lg leading-8 text-foreground">
          Lightning-Fast Web Data Collection Without Hassles
        </p>
        <div className="mt-10 flex items-center gap-x-6">
          <a href={`${url}/auth/register`}>
            <Button>Get started</Button>
          </a>
          <Button onClick={() => showMessages()} variant={"outline"}>
            Talk to an expert
          </Button>
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-col lg:flex-grow relative h-full items-center justify-center">
        <div className="w-full z-10">
          {countries && <ShowingGlobe countries={countries} />}
        </div>
      </div>
    </div>
  );
}
